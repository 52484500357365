<template>
  <div class="fr" style="cursor: pointer;">
    <a-icon v-if="!fullScreen" type="fullscreen" @click="requestFullScreen('enter')" />
    <a-icon v-else type="fullscreen-exit" @click="requestFullScreen" />
  </div>
</template>

<script>
import OnEvent from '../common/OnEvent'
import {mapState} from "vuex";
import store from "@/store";

export default {
  extends: OnEvent,
  props: {
    propValue: {
      type: String,
      required: true,
      default: '',
    },
    element: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      fullScreen: state => state.main.fullScreen,
    })
  },
  watch:{
    fullScreen(newValue){
      this.vm.$refs.openWindow.resizeJ()
    },
  },
  mounted() {
    function screenChange(e){
      store.commit('main/setFullScreen', document.fullscreen)
    }
    if(document.body.clientWidth>1360){
      store.commit('main/setFontSize', 18)
    }
    window.addEventListener('fullscreenchange',screenChange)
    this.$once('hook:beforeDestroy',()=>{
      window.removeEventListener('fullscreenchange',screenChange)
    })
  },
  methods: {
    // 控制是否全屏展示
    requestFullScreen(type) {
      if(type==='enter'){
        let element = document.body
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      }else{
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fr{
  width: 100%;
  color: #ffffff;
  font-size: 28px;
  line-height: 1.2em;
}
</style>
